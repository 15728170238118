































import Vue from 'vue';
import Component from 'vue-class-component';
import { Project } from '../model/project';

@Component
export default class Projects extends Vue {
  baseUrl: string = process.env.BASE_URL;
  projects: Project[] = [
    {
      title: 'Red Alert',
      image: 'red-alert.png',
      description:
        // tslint:disable-next-line:max-line-length
        'Website used for the yearly Red Alert party, organised by KLJ Hamme. A countdown timer, info about the venue, line-up and more can be found here.',
      links: {
        git: 'https://gitlab.com/GXGOW/red-alert',
      },
    },
  ];
}
